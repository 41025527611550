import { useState, useEffect } from 'react'

function createCallback(setState, isOnEnter) {
  let previousY = 0
  let previousRatio = 0
  return (entries) => {
    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y
      const currentRatio = entry.intersectionRatio
      const isIntersecting = entry.isIntersecting

      // Scrolling down/up
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          if (isOnEnter) setState(true) // scrolling down enter
        } else {
          if (!isOnEnter) setState(true) // scrolling down leave
        }
      } else if (currentY > previousY) {
        if (currentRatio < previousRatio) {
          if (isOnEnter) setState(false) // scrolling up leave
        } else if (isIntersecting) {
          if (!isOnEnter) setState(false) // scrolling up enter
        }
      }

      previousY = currentY
      previousRatio = currentRatio
    })
  }
}

export default (selectorId, isOnEnter = true) => {
  const [state, setState] = useState(!selectorId)
  useEffect(() => {
    if (!selectorId) return
    const target = document.querySelector(`#${selectorId}`)
    const observer = new window.IntersectionObserver(
      createCallback(setState, isOnEnter)
    )
    observer.observe(target)
    return () => {
      observer.unobserve(target)
      observer.disconnect()
    }
  }, [])
  return state
}
