import React from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import useIntersectionObserver from '../hooks/useIntersectionObserver'

const BackToTopButton = ({ selectorId }) => {
  const showBackToTopButton = useIntersectionObserver(selectorId, true)
  return (
    <>
      <noscript>
        <a
          href='#'
          className='button is-transparent icon back-to-top-button show'
        >
          <FontAwesomeIcon
            icon={faChevronCircleUp}
            size='2x'
            className='has-text-nude'
          />
        </a>
      </noscript>
      <a
        href='#'
        className={clsx('button is-transparent icon back-to-top-button', {
          show: showBackToTopButton
        })}
      >
        <FontAwesomeIcon
          icon={faChevronCircleUp}
          size='2x'
          className='has-text-nude'
        />
      </a>
    </>
  )
}

export default BackToTopButton
